@font-face {
  font-family: CirceLight;
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/CRC25.otf') format('opentype');
}
@font-face {
  font-family: CirceRegular;
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/CRC35.otf') format('opentype');
}
@font-face {
  font-family: CirceSemiBold;
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/CRC55.otf') format('opentype');
}

@font-face {
  font-family: CirceBold;
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/CRC65.otf') format('opentype');
}

body,
div,
p,
a,
input,
select,
table {
  font-family: CirceRegular;
}

h1,
h2,
h3,
h4,
label {
  font-family: CirceSemiBold;
}

@primary-color: #e62d39;@link-color: #e62d39;@success-color: #a0b149;@warning-color: #f5ad48;@error-color: #e62d39;@font-family: CirceRegular;@font-size: 14px;@heading-color: #000000;@text-color: #000000;@text-color-secondary: #918273;@disabled-color: rgba(0, 0, 0, 0.25);@border-radius: 2px;@border-color: #d9d9d9;